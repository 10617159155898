import React from "react"
import Img from "gatsby-image"

const PrilagodjenaSlika = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    // console.log("Properties=")
    // console.log(JSON.stringify(normalizedProps, null, 2))
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth ,
        maxHeight: window.innerHeight - 50,
        height: window.innerHeight - 50,
        // height: props.fluid.presentationHeight,
        // height: "auto",
     
        width: "auto",
        margin: "50px auto 0", // Used to center the image
      },
      imgStyle: {
        ...(props.imgStyle || {}),
        objectFit: 'contain',
      }
    }
  }

  return <Img {...normalizedProps} />
}
export default PrilagodjenaSlika