import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallerycomp"
// import YAMLSlike from "../data/galerija.yaml"

// const IndexPage = () => (
//   <Layout>
//     <SEO title="Galerija fotografija" />
//     <main id="main">
//     <h1>Galerija fotografija</h1>
//     </main>
//   </Layout>
// )

// export default IndexPage

const IndexPage = () => (
  <Layout>
    <SEO title="Galerija slika" />
    <main id="main">
      <StaticQuery
        query={graphql`
          {
            allGalerijaYaml {
              edges {
                node {
                  galerija
                  id
                  galerija_slike {
                    title
                    image {
                      absolutePath
                      id
                      childImageSharp {
                        fluid(maxWidth: 1500) {
                          aspectRatio
                          presentationWidth
                          presentationHeight
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <h1>Galerija slika</h1>
            {/* {data.allGalerijaYaml.edges.map(({ node }) => (
        <p>{node.title}</p>
      ))} */}
            <Gallery slike={data.allGalerijaYaml.edges} />

            {/* <Gallery
      itemsPerRow={3} // This will be changed to `[2, 3]` later
      images={data.allGalerijaYaml.edges.map(({ node }) => ({
        ...node.image.childImageSharp.fluid,
        caption: `${node.title}`,
      }))}
    /> */}
          </>
        )}
      />
    </main>
  </Layout>
)

export default IndexPage
