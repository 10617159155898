import React, { useState } from "react"
// import PropTypes from 'prop-types';
import Img from "gatsby-image"
import LightBox from "./lightbox"

// import { chunk, sum } from 'lodash';
// import { Box } from 'rebass';
// import Layout from "../components/layout"
// import SEO from "../components/seo"

// const Gallery = ({ images, itemsPerRow }) => {
//   // Split images into groups of the given size
//   const rows = chunk(images, itemsPerRow);

//   return (
//     <div>
//        {rows.map(row => {
//          const rowAspectRatioSum = sum(row.map(image => image.aspectRatio));

//          return row.map(image => (
//                 // <p><Img fluid={image} className=""/></p>
//                     <Box
//                       key={image.src}
//                       as={Img}
//                       fluid={image}
//                       title={image.caption}
//                       width={`${(image.aspectRatio / rowAspectRatioSum) * 100}%`}
//                       css={{ display: 'inline-block' }}
//                     />
//                   ));
//   })}
//       </div>
//   );
// };

const Gallery = ({ slike }) => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [lightboxSlike, setLightboxSlike] = useState(null)

  const handleOpen = (i, slikice) => e => {
    // console.log(i)
    setLightboxSlike(slikice)
    // console.log(lightboxSlike)
    // console.log(e)
    // console.log(slikice)
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => e => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => e => {
    setSelectedImage((i + 1) % length)
  }

  return (
    <section className="lightbox-container">
      {slike.map((galerija, gi) => (
        <>
          <h2 key={galerija.node.id}>{galerija.node.galerija}</h2>
          <section className="galerija-grupa">
            {galerija.node.galerija_slike.map((slika, si) => (
              <figure
                className="galerija-slika"
                key={si}
                onClick={handleOpen(si, galerija.node.galerija_slike)}
              >
                <Img
                  // onClick={handleOpen(slika.image.id)}
                  key={slika.image.id}
                  // key={slika.image.childImageSharp.fluid.src}
                  fluid={slika.image.childImageSharp.fluid}
                />
                <p className="caption">{slika.title}</p>
              </figure>
            ))}
            {showLightbox && selectedImage !== null && (
              <LightBox
                // images={galerija.node.galerija_slike}
                images={lightboxSlike}
                handleClose={handleClose}
                handleNextRequest={handleNextRequest}
                handlePrevRequest={handlePrevRequest}
                selectedImage={selectedImage}
              />
            )}
          </section>
        </>
      ))}
    </section>
  )
}

export default Gallery
